<template>
  <div class="testDeeplinksPage">
    <ExportData msg="TestDeeplinksPage" />
  </div>
</template>

<script>
// @ is an alias to /src
import ExportData from "@/components/testPage.vue";

export default {
  name: "testDeeplinksPage",
  components: {
    ExportData
  }
};
</script>
