<template>
  <div class="exportdata">
    <ExportData msg="MPS Console" />
  </div>
</template>

<script>
// @ is an alias to /src
import ExportData from "@/components/ExportData.vue";

export default {
  name: "exportData",
  components: {
    ExportData
  }
};
</script>
