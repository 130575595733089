<template>
  <div class="home">
    <HelloWorld msg="Phishing Site List Console" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/PhishingSiteListLogin.vue";

export default {
  name: "PhishingSiteListLogin",
  components: {
    HelloWorld
  }
};
</script>
