<template>
  <div class="saleskeyword">
    <ExportData msg="Sales Keyword" />
  </div>
</template>

<script>
// @ is an alias to /src
import ExportData from "@/components/SalesKeyword.vue";

export default {
  name: "salesKeyword",
  components: {
    ExportData
  }
};
</script>
