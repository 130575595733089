<template>
  <div class="data">
    <Data msg="Phishing Site List Console" />
  </div>
</template>

<script>
// @ is an alias to /src
import Data from "@/components/PhishingSiteListConsole.vue";

export default {
  name: "PhishingSiteListConsole",
  components: {
    Data
  }
};
</script>
