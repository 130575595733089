        <template>
            <div>
                <div class="show_data" style="margin-top:-24px;margin-right: 24px">
                    <div v-if="this.$store.state.token">
                        <p align="right">
                        <el-row align="right">
                            <el-button @click.native="logout()" icon="el-icon-user-solid">{{this.$store.state.username}}</el-button>
                        </el-row>
                        </p>
                    </div>
                    <div v-else>
                        <p align="right">
                            <router-link to="/" align="right">Login</router-link>
                        </p>
                    </div>

                    <h2>Coupon Console</h2>

                  <div ref="dashboard" class="info" style="margin-top:80px;margin-bottom:20px">

                    <el-select v-model="partnerValue" filterable placeholder="Choose Partner">
                      <el-option
                        v-for="item in partnerOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>

                    <el-input style="margin-left:20px;width: 160px"
                      placeholder="Coupon Count"
                      v-model="couponCount"
                      type="number"
                      clearable>
                    </el-input>

                    <el-button type="primary" style="margin-left:20px" @click="generatedCoupon" :loading=this.btnLoading>{{this.btnName}}</el-button>

                    <div align="center" style="margin-top: 64px">
                           <el-card class="box-card">
                              <div slot="header" class="clearfix">
                                <span>Coupon Code </span>
                                <el-button style="float: right; padding: 3px 0" @click="doCopy(couponList)" type="text">Copy to clipboard</el-button>
                              </div>
                              <div v-for="o in couponList" :key="o" class="text item">
                                {{ o }}
                              </div>
                            </el-card>
                    </div>
                  </div>

            </div>
            </div>
        </template>

        <script>


        export default {
            data() {
                return {
                    partnerOptions: [{
                              value: 'n/a',
                              label: 'Waiting...'
                            }],
                    partnerValue: '',
                    couponCount:'',
                    couponList:[],
                    btnLoading:false,
                    btnName:'Generate'

                }
            },
            mounted: function() {
                this.loadEvents();


            },
            beforeDestroy() {

            },
            watch: {

            },
            methods: {
                /* eslint-disable */
                logout() {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.$store.commit('clearToken');
                    this.$router.push('/couponLogin')
                },
                doCopy: function (val) {


                  var that = this;
                  this.$copyText(JSON.stringify(val).replace(/\[|]/g, '')).then(function (e) {
                      that.$notify({
                          title: 'Success',
                          message: 'Already copy to clipboard.',
                          type: 'success'
                        });
                  }, function (e) {
                     that.$notify({
                          title: 'Note',
                          message: 'Copy fail, please try again.',
                          type: 'warning'
                        });
                  })
                },
                generatedCoupon() {

                    if (this.partnerValue == '' || this.partnerValue == 'n/a'){

                        this.$notify({
                          title: 'Note',
                          message: 'Please select a partner.',
                          type: 'warning'
                        });

                        return;

                    }

                    if (this.couponCount == ''){

                        this.$notify({
                          title: 'Note',
                          message: 'Please input coupon code count.',
                          type: 'warning'
                        });

                        return;

                    }

                    var that = this;
                    this.btnLoading = true;
                    this.$axios.get('/api/event_feed/generatedCoupon/', {
                            params: {
                               partner:this.partnerValue,
                                count:this.couponCount
                            },
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {

                            this.btnLoading = false;
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                  this.couponList = result['data']



                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            this.btnLoading = false;
                            alert('Unknown Error, please try again : '+arg)
                            that.logout()

                        })

                },
                loadEvents() {
                    var that = this;
                    this.$axios.get('/api/event_feed/getCouponPartner/', {
                            params: {},
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                    this.partnerOptions = result['data']
                                    // this.appDetectionChartName = result['app_detection_chart']['name']
                                    // this.appDetectionChartData = result['app_detection_chart']['data']
                                    // this.eventTypeChartData = result['event_type_chart']['data']
                                    // this.eventTypeChartName = result['event_type_chart']['name']
                                    // this.eventRecentChartDate = result['event_recent_chart']['date']
                                    // this.eventDayCountChartData = result['event_recent_chart']['event']
                                    // this.platformDailyDate = result['platform_chart']['date']
                                    // this.platformDailyAndroidData = result['platform_chart']['android']
                                    // this.platformDailyiOSData = result['platform_chart']['ios']
                                    // this.platformData = result['platform_chart']['platform']
                                    // this.platformVersionName = result['platform_chart']['version_name']
                                    // this.platformVersionData = result['platform_chart']['version_data']
                                    // this.eventDAUChartData = result['event_recent_chart']['dau']

                                    // this.reload()

                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            alert('Unknown Error, please try again : '+arg)
                            that.logout()

                        })
                }
            }

        }
        </script>

        <style scoped>
        h1,
        h2 {
            font-weight: normal;
        }

        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            display: inline-block;
            margin: 0 10px;
        }

        a {
            color: #42b983;
        }

        .el-table .warning-row {
          background: #42b983;
        }

        .el-table .success-row {
          background: #f0f9eb;
        }
        .text {
          font-size: 14px;
        }

        .item {
          margin-bottom: 18px;
        }

        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }
        .clearfix:after {
          clear: both
        }

        .box-card {
          width: 480px;
        }
        </style>
