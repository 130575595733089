<template>
  <div class="data">
    <Data msg="MPS Console" />
  </div>
</template>

<script>
// @ is an alias to /src
import Data from "@/components/Data.vue";

export default {
  name: "ShowData",
  components: {
    Data
  }
};
</script>
