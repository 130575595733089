<template>
  <div class="data">
    <Data msg="Smartone Console" />
  </div>
</template>

<script>
// @ is an alias to /src
import Data from "@/components/SMTConsole.vue";

export default {
  name: "SMTConsole",
  components: {
    Data
  }
};
</script>
