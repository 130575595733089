    <template>
        <div>
            <div class="exportdata">

                <div v-if="this.$store.state.token">
                    <p align="right">
                    <el-row align="right">
                        <el-button type="primary" @click.native="gohome()" icon="el-icon-s-data">Home</el-button>
                        <el-button @click.native="logout()" icon="el-icon-user-solid">{{this.$store.state.username}}</el-button>
                    </el-row>
                    </p>
                </div>
                <div v-else>
                        <p align="right">
                            <router-link to="/" align="right">Login</router-link>
                        </p>
                    </div>
                <h2>Advance Mobility Export Data</h2>

                <div class="block">
                    <span class="demonstration">Start Date</span>
                    <el-date-picker v-model="startDate" type="date" placeholder="Select start date" :picker-options="pickerOptionsStart" :default-value="startDate" @change="changeEnd">
                    </el-date-picker>
                </div>

                <div class="block">
                    <span class="demonstration">End Date</span>
                    <el-date-picker v-model="endDate" type="date" placeholder="Select end date" :picker-options="pickerOptionsEnd" :default-value="endDate" @change="changeStart">
                    </el-date-picker>
                </div>

                <el-button type="primary" @click="exportdata" :loading=this.btnLoading>{{this.btnName}}</el-button>

            </div>
        </div>
    </template>

    <script>
    export default {
        data() {
            return {
                pickerOptionsStart: {},
                pickerOptionsEnd:{},
                startDate: '',
                endDate: '',
                btnName:'Export',
                btnLoading: false,
            }
        },
        created(){
            let that = this;
            that.endDate = new Date();
            that.startDate = new Date();
            that.startDate.setMonth(new Date().getMonth() - 1);
            that.pickerOptionsStart = Object.assign({},this.pickerOptionsStart,{
                  disabledDate: (time) => {
                    return time.getTime() > this.endDate || time.getTime() > Date.now() - 8.64e7 + 24 * 3600 * 1000
                  }
                });
            that.pickerOptionsEnd = Object.assign({},this.pickerOptionsEnd,{
                  disabledDate: (time) => {
                    return time.getTime() < this.startDate || time.getTime() > Date.now() - 8.64e7 + 24 * 3600 * 1000
                    }
                });
        },
        methods: {
            /* eslint-disable */
            exportdata() {
                var that = this;
                this.btnName = 'Loading data, please waiting, do not refresh page...'
                this.btnLoading = true
                // this.$axios.post('/api/event_feed/exportData/', {
                //                 params: {
                //                     startdate:this.startDate,
                //                     enddate:this.endDate
                //                 },
                //                 responseType: 'blob',
                //                 headers: { 'Authorization': 'JWT ' + this.$store.state.token,'Content-Type': 'application/json' }
                //             })
                    this.$axios.request({
                                url: '/api/event_feed/exportData/',
                                method: 'GET',
                                params: {
                                    startdate:this.startDate,
                                    enddate:this.endDate
                                },
                                responseType: 'blob',
                                headers: { 'Authorization': 'JWT ' + this.$store.state.token,'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                            .then((response) => {
                                // var result = res.data;

                                if (response.status === 401) {

                                    alert('Login timed out, please log in again')
                                    that.logout()
                                }
                                  let filename = response.headers['content-disposition'].match(/filename=(.*)/)[1]
                                  // 将二进制流转为blob
                                  const blob = new Blob([response.data], {type: response.headers['Content-Type']})
                                  if (typeof window.navigator.msSaveBlob !== 'undefined') {
                                    // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
                                    window.navigator.msSaveBlob(blob, decodeURI(filename))
                                      this.btnName = 'Export'
                                      this.btnLoading = false
                                  } else {
                                    // 创建新的URL并指向File对象或者Blob对象的地址
                                    const blobURL = window.URL.createObjectURL(blob)
                                    // 创建a标签，用于跳转至下载链接
                                    const tempLink = document.createElement('a')
                                    tempLink.style.display = 'none'
                                    tempLink.href = blobURL
                                    tempLink.setAttribute('download', decodeURI(filename))
                                    // 兼容：某些浏览器不支持HTML5的download属性
                                    if (typeof tempLink.download === 'undefined') {
                                      tempLink.setAttribute('target', '_blank')
                                    }
                                    // 挂载a标签
                                    document.body.appendChild(tempLink)
                                    tempLink.click()
                                    document.body.removeChild(tempLink)
                                    // 释放blob URL地址
                                    window.URL.revokeObjectURL(blobURL)
                                      this.btnName = 'Export'
                                      this.btnLoading = false
                                  }
                            }).catch(function(arg) {

                                alert('Login timed out, please log in again')
                                this.btnName = 'Export'
                                this.btnLoading = false
                                that.logout()


                            })
            },
            logout() {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.$store.commit('clearToken');
                    this.$router.push('/')
                },
            gohome() {

                    this.$router.back()
                },
            changeStart (){
                this.pickerOptionsStart = Object.assign({},this.pickerOptionsStart,{
                  disabledDate: (time) => {
                    return time.getTime() > this.endDate || time.getTime() > Date.now() - 8.64e7 + 24 * 3600 * 1000
                  }
                })
              },
            changeEnd (){
                this.pickerOptionsEnd = Object.assign({},this.pickerOptionsEnd,{
                  disabledDate: (time) => {
                        return time.getTime() < this.startDate || time.getTime() > Date.now() - 8.64e7 + 24 * 3600 * 1000
                    }
                })
              }
            /* eslint-enable */
        }
    }
    </script>

    <style scoped>
        .block{
            margin: 30px;
        }
        .exportdata {
            width: 100%;
            margin: 0 auto;
        }
    </style>