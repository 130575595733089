    <template>
        <div>
            <div class="saleskeyowrd">

                <p align="right">
                    <el-row align="right">
                        <el-button type="primary" @click.native="gohome()" icon="el-icon-s-data">肉骨茶</el-button>
                    </el-row>
                </p>

                <h2>Sales Keyword</h2>
                <div class="inputkeyword" align="center" style="width:300px;margin:0 auto;">
                <el-input align="center" placeholder="Please input the keyword" v-model="input" @keyup.enter.native="exportdata" clearable> </el-input>
                </div>
              <div class="btnkeyword" align="center" style="width:300px;margin:0 auto;margin-top: 30px;">
                <el-button type="primary" @click="exportdata" :loading=this.btnLoading>{{this.btnName}}</el-button>
              </div>
              <div class="page_info" style="margin-right: 30px;" v-show="this.next">

                <p align="right">
                    <el-row align="right">
                        <el-button type="primary" @click.native="prevPage()" v-show="this.prev">Prev</el-button>
                        <el-button type="text" disabled>Pages : {{this.page+1}} / {{this.total_pages}}</el-button>
                        <el-button type="primary" @click.native="nextPage()" >Next</el-button>
                    </el-row>
                </p>
              </div>
              <div class="resultlist" align="center" style="margin:0 auto;margin-top: 30px;margin-bottom: 30px;margin-left: 30px;margin-right: 30px;">
                <el-table :data="dataList" default-text="N/A" style="width: 100%" border stripe :row-class-name="tableRowClassName">
                            <el-table-column type="index" fixed width="50"></el-table-column>

                            <el-table-column prop="item_url" label="Product Name" min-width="100">
                              <template slot-scope="scope">
                                <a :href="scope.row.item_url"
                                  target="_blank"
                                  class="buttonText">{{scope.row.item_name}}</a>
                              </template>
                            </el-table-column>
                            <el-table-column prop="item_sales" sortable label="Sales Number" min-width="100">
                            </el-table-column>
                            <el-table-column prop="item_original_price" sortable label="原價" min-width="100">
                            </el-table-column>
                            <el-table-column prop="item_currently_price" sortable default-text="N/A" label="特價" min-width="100">
                            </el-table-column>
                            <el-table-column prop="item_store" sortable label="供應商" min-width="100"></el-table-column>

                </el-table>
              </div>

            </div>
        </div>
    </template>

    <script>
    export default {
        data() {
            return {
                input: '',
                lastInput:'',
                page:0,
                total_pages:0,
                next:false,
                prev:false,
                dataList: [],
                btnName:'Search',
                btnLoading: false,
            }
        },
        created(){
            //let that = this;
        },
        methods: {
            /* eslint-disable */
            exportdata() {
                var that = this;

                if (this.input != this.lastInput){

                  this.page = 0
                  this.total_pages = 0
                  this.next = false
                  this.dataList = []
                }

                this.btnName = 'Searching for data, please waiting, do not refresh page...'
                this.btnLoading = true
                // this.$axios.post('/api/event_feed/exportData/', {
                //                 params: {
                //                     startdate:this.startDate,
                //                     enddate:this.endDate
                //                 },
                //                 responseType: 'blob',
                //                 headers: { 'Authorization': 'JWT ' + this.$store.state.token,'Content-Type': 'application/json' }
                //             })
                    this.$axios.request({
                                url: '/api/event_feed/salekeyword/',
                                method: 'GET',
                                params: {
                                    keyword:this.input,
                                    page:this.page
                                }
                            })
                            .then((response) => {

                                var result = response.data;

                                this.lastInput = this.input

                                if (response.status == 200) {

                                    if (result['msg']=='success'){

                                        this.dataList = result['data']
                                        this.total_pages = result['page']

                                        if (result['next'] == 'enable'){

                                          this.next = true

                                        }else {

                                          if (this.total_pages>1){

                                            this.next = true

                                          }else {

                                            this.next = false
                                          }


                                        }

                                        if (this.page>=1){

                                            this.prev = true
                                        }else {
                                            this.prev = false
                                        }

                                    }else {

                                        alert(result['msg'])
                                    }

                                    this.btnName = 'Search'
                                    this.btnLoading = false

                                } else {
                                    alert('Unknown Error, please try again1')

                                }

                            }).catch(function(arg) {

                                alert('Unknown Error, please try again : '+arg)
                                that.btnName = 'Search'
                                that.btnLoading = false


                            })
            },
            prevPage() {



                    if ((this.page-1) < 0){

                      alert('No more data')

                    }else {
                      this.page = this.page - 1
                      this.exportdata()
                    }
                },
            nextPage() {

                    if ((this.page+1) >= this.total_pages){

                      alert('No more data')

                    }else {

                      this.page = this.page + 1
                      this.exportdata()
                    }

                },
            gohome() {

                      this.$axios.request({
                                url: '/api/event_feed/downloadKeywordData/',
                                method: 'GET',
                                params: {
                                },
                                responseType: 'blob',
                                headers: { 'Authorization': 'JWT ' + this.$store.state.token,'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                            .then((response) => {
                                // var result = res.data;

                                  let filename = response.headers['content-disposition'].match(/filename=(.*)/)[1]
                                  // 将二进制流转为blob
                                  const blob = new Blob([response.data], {type: response.headers['Content-Type']})
                                  if (typeof window.navigator.msSaveBlob !== 'undefined') {
                                    // 兼容IE，window.navigator.msSaveBlob：以本地方式保存文件
                                    window.navigator.msSaveBlob(blob, decodeURI(filename))

                                  } else {
                                    // 创建新的URL并指向File对象或者Blob对象的地址
                                    const blobURL = window.URL.createObjectURL(blob)
                                    // 创建a标签，用于跳转至下载链接
                                    const tempLink = document.createElement('a')
                                    tempLink.style.display = 'none'
                                    tempLink.href = blobURL
                                    tempLink.setAttribute('download', decodeURI(filename))
                                    // 兼容：某些浏览器不支持HTML5的download属性
                                    if (typeof tempLink.download === 'undefined') {
                                      tempLink.setAttribute('target', '_blank')
                                    }
                                    // 挂载a标签
                                    document.body.appendChild(tempLink)
                                    tempLink.click()
                                    document.body.removeChild(tempLink)
                                    // 释放blob URL地址
                                    window.URL.revokeObjectURL(blobURL)

                                  }
                            }).catch(function(arg) {

                                alert('Download fail, please try again later.  '+arg)



                            })

                }
            /* eslint-enable */
        }
    }
    </script>

    <style scoped>
        .block{
            margin: 30px;
        }
        .exportdata {
            width: 100%;
            margin: 0 auto;
        }
    </style>