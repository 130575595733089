<template>
  <div>
    <div class="show_data" style="margin-bottom: 36px;">
      <div
        v-if="this.$store.state.token"
        style="margin-top: -48px;margin-bottom: 36px;"
      >
        <p align="right">
          <el-row align="right">
            <el-button @click.native="logout()" icon="el-icon-user-solid">{{
              this.$store.state.username
            }}</el-button>
          </el-row>
        </p>
      </div>
      <div v-else style="margin-top: -48px;margin-bottom: 36px;">
        <p align="right">
          <router-link to="/" align="right">Login</router-link>
        </p>
      </div>
      <h1><b>HKTV Mall Report</b></h1>
      <p align="left">
        <el-row align="left">
          <div style="margin-left: 30px;margin-right: 30px;">
            <span style="font-size:24px;"
              ><b>{{ this.todayTitle }}</b></span
            >
            <el-divider></el-divider>
            <span style="font-size:18px;color: #0099FF;"
              >Number of Order : <b>{{ this.todayOrder }}</b> Order Amount
              (Total) : <b>{{ this.todaySum }}</b
              >&nbsp;&nbsp;Order Amount (Average) :
              <b>{{ this.todayAvgSum }}</b></span
            >
          </div>
        </el-row>
      </p>

      <div style="display:flex;margin-left: 30px;margin-right: 30px;">
        <div
          ref="chart_today_order"
          class="chart_line"
          style="height:500px; width:33.33333%; margin-right:20px"
        ></div>

        <div
          ref="chart_today_toporder"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>

        <div
          ref="chart_today_todaysales"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>
      </div>

      <p align="left">
        <el-row align="left">
          <div style="margin-left: 30px;margin-right: 30px;">
            <span style="font-size:24px;"
              ><b>{{ this.sdayTitle }}</b></span
            >
            <el-divider></el-divider>
            <span style="font-size:18px;color: #0099FF;"
              >Number of Order : <b>{{ this.sdayOrder }}</b> Order Amount
              (Total) : <b>{{ this.sdaySum }}</b
              >&nbsp;&nbsp;</span
            >
          </div>
        </el-row>
      </p>
      <p align="left">
        <el-row align="left">
          <div style="margin-left: 30px;margin-right: 30px;">
            <span style="font-size:18px;color: #0099FF;"
              >7-day order average : <b>{{ this.sdayAvgOrder }}</b> 7-day sales
              average : <b>{{ this.sdayAvgSum }}</b
              >&nbsp;&nbsp;</span
            >
          </div>
        </el-row>
      </p>
      <div style="display:flex;margin-left: 30px;margin-right: 30px;">
        <div
          ref="chart_sday_order"
          class="chart_line"
          style="height:500px; width:33.33333%; margin-right:20px"
        ></div>

        <div
          ref="chart_sday_toporder"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>

        <div
          ref="chart_sday_todaysales"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>
      </div>

      <p align="left">
        <el-row align="left">
          <div style="margin-left: 30px;margin-right: 30px;">
            <span style="font-size:24px;"
              ><b>{{ this.tdayTitle }}</b></span
            >
            <el-divider></el-divider>
            <span style="font-size:18px;color: #0099FF;"
              >Number of Order : <b>{{ this.tdayOrder }}</b> Order Amount
              (Total) : <b>{{ this.tdaySum }}</b
              >&nbsp;&nbsp;</span
            >
          </div>
        </el-row>
      </p>
      <p align="left">
        <el-row align="left">
          <div style="margin-left: 30px;margin-right: 30px;">
            <span style="font-size:18px;color: #0099FF;"
              >30-day order average : <b>{{ this.tdayAvgOrder }}</b> 30-day
              sales average : <b>{{ this.tdayAvgSum }}</b
              >&nbsp;&nbsp;</span
            >
          </div>
        </el-row>
      </p>
      <div style="display:flex;margin-left: 30px;margin-right: 30px;">
        <div
          ref="chart_tday_order"
          class="chart_line"
          style="height:500px; width:33.33333%; margin-right:20px"
        ></div>

        <div
          ref="chart_tday_toporder"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>

        <div
          ref="chart_tday_todaysales"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>
      </div>

      <p align="left">
        <el-row align="left">
          <div style="margin-left: 30px;margin-right: 30px;">
            <span style="font-size:24px;"><b>Stock</b> <b>{{ this.stockDate }}</b> </span>
            <el-divider></el-divider>
            <el-button type="primary" @click="inputEmail">Low Stock Notice</el-button>
          </div>
        </el-row>
      </p>

      <el-table
        :data="stockList"
        default-text="N/A"
        style="margin-left: 30px;margin-right: 30px;"
        stripe
        border
      >
        <el-table-column
          prop="merchantSKUID"
          label="Merchant SKU ID"
          max-width="50"
        >
        </el-table-column>
        <el-table-column prop="skuNameCN" label="SKU Name" min-width="100">
        </el-table-column>
        <el-table-column
          prop="stockLevel"
          default-text="0"
          label="Stock Level"
          max-width="30"
          sortable
        >
           <template slot-scope="{row}">
          <el-tag :type="row.stockLevel == undefined ? 0 : row.stockLevel | statusFilter">
            {{ row.stockLevel == undefined ? 0 : row.stockLevel }}
          </el-tag>
        </template>

        </el-table-column>
      </el-table>

      <p align="left">
        <el-row align="left">
          <div style="margin-left: 30px;margin-right: 30px;">
            <span style="font-size:24px;"><b>Other</b></span>
            <el-divider></el-divider>
          </div>
        </el-row>
      </p>

      <div style="display:flex;margin-left: 30px;margin-right: 30px;">
        <div
          ref="chart_exp_week"
          class="chart_line"
          style="height:500px; width:33.33333%; margin-right:20px"
        ></div>

        <div
          ref="chart_exp_toporder"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>

        <div
          ref="chart_exp_todaysales"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>
      </div>

      <p align="left">
        <el-row align="left">
          <div style="margin-left: 30px;margin-right: 30px;">
            <span style="font-size:24px;"><b>Tools</b></span>
            <el-divider></el-divider>
          </div>
        </el-row>
      </p>

      <div
        ref="sale_period_data"
        style="height:500px; width:33.33333%; margin-right:20px"
      >
        <div class="block" style="margin-bottom:20px">
          <span class="demonstration" style="margin-right:20px"
            >Start Date</span
          >

          <el-date-picker
            v-model="startDate"
            type="date"
            placeholder="Select start date"
            :picker-options="pickerOptionsStart"
            :default-value="startDate"
            @change="changeEnd"
          >
          </el-date-picker>
        </div>

        <div class="block">
          <span class="demonstration" style="margin-right:20px">End Date</span>

          <el-date-picker
            v-model="endDate"
            type="date"
            placeholder="Select end date"
            :picker-options="pickerOptionsEnd"
            :default-value="endDate"
            @change="changeStart"
          >
          </el-date-picker>
        </div>

        <el-button
          type="primary"
          style="margin-top:20px"
          @click="exportdata"
          :loading="this.btnLoading"
          >{{ this.btnName }}</el-button
        >

        <div
          ref="sale_period_data_result"
          class="info"
          style="display: none;margin-top:20px;margin-bottom:20px"
        >
          <p align="left">
            <el-row align="left">
              <div style="margin-left: 30px;margin-right: 30px;">
                <el-divider></el-divider>
                <span style="font-size:18px;color: #0099FF;"
                  >Number of Order : <b>{{ this.periodDayOrder }}</b> Order
                  Amount (Total) : <b>{{ this.periodDaySum }}</b
                  >&nbsp;&nbsp;</span
                >
              </div>
            </el-row>
          </p>
          <p align="left">
            <el-row align="left">
              <div style="margin-left: 30px;margin-right: 30px;">
                <span style="font-size:18px;color: #0099FF;"
                  >Days Order Average :
                  <b>{{ this.periodDayAvgOrder }}</b> &nbsp;</span
                >
              </div>
            </el-row>
          </p>

          <p align="left">
            <el-row align="left">
              <div style="margin-left: 30px;margin-right: 30px;">
                <span style="font-size:18px;color: #0099FF;"
                  >Sales Average (By Days):
                  <b>{{ this.periodDayAvgSum }}</b></span
                >
              </div>
            </el-row>
          </p>

          <p align="left">
            <el-row align="left">
              <div style="margin-left: 30px;margin-right: 30px;">
                <span style="font-size:18px;color: #0099FF;"
                  >Sales Average (By Orders) :
                  <b>{{ this.periodDaySalesOrderAvg }}</b></span
                >
              </div>
            </el-row>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import echarts from 'echarts'
var echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
require("echarts/lib/chart/pie");

require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
// require("echarts/lib/component/dataZoom");
// require("echarts/lib/component/markPoint");
// require("echarts/lib/component/markLine");
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 6000;
export default {
  name: "show_data",
  inject: ["reload"],
    filters: {
    statusFilter(status) {

      if (status>=10){

        return 'success'
      }else  if (status >=5 && status< 10){

        return 'warning'
      }else  if (status < 5){

        return 'danger'
      }

    }
  },
  data() {
    return {
      seriesData: [],
      todayTitle: "Today",
      todaySum: "0",
      todayAvgSum: "0",
      todayOrder: "0",
      todayOrderChart: null,
      todayTopOrderChart: null,
      todayTopSalesChart: null,
      todayTopSaleData: [],
      todayTopSaleNameData: [],
      todayTopOrderData: [],
      todayTopOrderNameData: [],
      todayRealTimeOrderData: [],
      sdayTitle: "Last 7 Days",
      sdaySum: "0",
      sdayOrder: "0",
      sdayAvgSum: "0",
      sdayAvgOrder: "0",
      sdayOrderChart: null,
      sdayTopOrderChart: null,
      sdayTopSalesChart: null,
      sdayTopSaleData: [],
      sdayTopSaleNameData: [],
      sdayTopOrderData: [],
      sdayTopOrderNameData: [],
      sdayOrderData: [],
      sdaySalesData: [],
      sdaySalesAvgData: [],
      sdaySalesMAData: [],
      sdayOrderMAData: [],
      sdayOrderDateData: [],
      tdayTitle: "Last 30 Days",
      tdaySum: "0",
      tdayOrder: "0",
      tdayAvgSum: "0",
      tdayAvgOrder: "0",
      tdayOrderChart: null,
      tdayTopOrderChart: null,
      tdayTopSalesChart: null,
      tdayTopSaleData: [],
      tdayTopSaleNameData: [],
      tdayTopOrderData: [],
      tdayTopOrderNameData: [],
      tdayOrderData: [],
      tdaySalesData: [],
      tdaySalesAvgData: [],
      tdaySalesMAData: [],
      tdayOrderMAData: [],
      tdayOrderDateData: [],
      expWeekChart: null,
      expWeekDateData: [],
      expWeekData: [],
      input: "",
      timer: "",

      pickerOptionsStart: {},
      pickerOptionsEnd: {},
      startDate: "",
      endDate: "",
      btnName: "Get Data",
      btnLoading: false,
      periodDaySum: "0",
      periodDayOrder: "0",
      periodDayAvgSum: "0",
      periodDayAvgOrder: "0",
      periodDaySalesOrderAvg: "0",

      stockList: [],
      stockDate: "",
      noticeemail: ''
    };
  },
  created() {
    let that = this;
    that.endDate = new Date();
    that.startDate = new Date();
    that.startDate.setMonth(new Date().getMonth() - 1);
    that.pickerOptionsStart = Object.assign({}, this.pickerOptionsStart, {
      disabledDate: time => {
        return (
          time.getTime() > this.endDate ||
          time.getTime() > Date.now() - 8.64e7 + 24 * 3600 * 1000
        );
      }
    });
    that.pickerOptionsEnd = Object.assign({}, this.pickerOptionsEnd, {
      disabledDate: time => {
        return (
          time.getTime() < this.startDate ||
          time.getTime() > Date.now() - 8.64e7 + 24 * 3600 * 1000
        );
      }
    });
  },
  mounted: function() {
    this.loadEvents();
    this.load7Days();
    this.load30Days();
    this.loadExp();
    this.loadStock();
    // this.timer = setInterval(this.loadEvents, 60000)
    this.$nextTick(() => {
      this.initPieCharts();
    });
    setTimeout(() => {
      this.seriesData.push([{ value: 123, name: "test" }]);
    }, 1000);
  },
  beforeDestroy() {
    if (this.todayTopOrderChart) {
      this.todayTopOrderChart.dispose();
      this.todayTopOrderChart = null;
    }

    if (this.todayOrderChart) {
      this.todayOrderChart.dispose();
      this.todayOrderChart = null;
    }

    if (this.todayTopSalesChart) {
      this.todayTopSalesChart.dispose();
      this.todayTopSalesChart = null;
    }

    clearInterval(this.timer);
  },
  watch: {
    seriesData(val) {
      this.setPieOptions(val);
    },
    deep: true
  },
  methods: {
    /* eslint-disable */
      initPieCharts() {

        var chart_dv = this.$refs.chart_today_toporder;
        if (chart_dv) {

          this.todayTopOrderChart = echarts.init(chart_dv, 'macarons')
          this.todayTopOrderChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

          this.todayTopOrderChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              // left: 'center',
              // bottom: '10',
              orient: 'vertical',
              left: 'right',
              data: this.todayTopSaleNameData
            },
            title: { text: 'Top Sales' },
            series: [{
              name: 'Top Sales',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.todayTopSaleData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })
        }


        var chart_bardv = this.$refs.chart_today_todaysales;
        if (chart_bardv) {

          this.todayTopSalesChart = echarts.init(chart_bardv, 'macarons')

          this.todayTopSalesChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

          this.todayTopSalesChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              // left: 'center',
              // bottom: '10',
              orient: 'vertical',
              left: 'right',
              data: this.todayTopOrderNameData
            },
            title: { text: 'Top Orders' },
            series: [{
              name: 'Top Orders',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.todayTopOrderData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }

        var chart_linedv = this.$refs.chart_today_order;
        if (chart_linedv) {

          this.todayOrderChart = echarts.init(chart_linedv, 'macarons')

          this.todayOrderChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

          this.todayOrderChart.setOption({
            tooltip: {
              trigger: 'axis',
              formatter: function (params) {
                params = params[0];
                var date = new Date(params.name);
                var hour = date.getHours();
                var minutes = date.getMinutes();
                if(hour < 10){
                  hour = '0' + hour;
                }
                if(minutes < 10){
                  minutes = '0' + minutes;
                }
                var dateStr = hour + ':' + minutes;
                return dateStr + ' ' + params.value[1];
              },
              axisPointer: {
                animation: false
              }
            },
            xAxis: {
              type: 'time',
              splitLine: {
                show: false
              },
              splitNumber:12,
              axisLine:{
                lineStyle:{
                  color: '#1B2232'
                }
              },
              axisLabel:{
                formatter:function(value,index){
                  var date = new Date(value);
                  var hour = date.getHours();
                  var minutes = date.getMinutes();
                  if(hour < 10){
                    hour = '0' + hour;
                  }
                  if(minutes < 10){
                    minutes = '0' + minutes;
                  }
                  return hour + ':' + minutes;
                },
                color:'#1B2232'
              }
            },
            yAxis: {
              name:'Order',
              nameTextStyle:{
                color: '#1B2232',
                fontSize:15
              },
              type: 'value',
              boundaryGap: [0, '100%'],
              splitLine: {
                show: false
              },
              axisLine:{
                lineStyle:{
                  color: '#1B2232'
                }
              },
              axisLabel:{
                color:'#1B2232'
              }
            },
            series: [{
              name: '',
              type: 'bar',
              hoverAnimation: false,
              smooth: true,
              symbolSize: 4,
              data: this.todayRealTimeOrderData

            }],
            color: ['#88d8b0','#88d8b0','#5bc0de','#5cb85c','#428bca','#ffcc5c'],

            title: { text: 'Today Orders' }

          })


        }

        //7d

        var chart_7dv = this.$refs.chart_sday_toporder;
        if (chart_7dv) {

          this.sdayTopOrderChart = echarts.init(chart_7dv, 'macarons')
          this.sdayTopOrderChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );
          this.sdayTopOrderChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              // left: 'center',
              // bottom: '10',
              orient: 'vertical',
              left: 'right',
              data: this.sdayTopSaleNameData
            },
            title: { text: 'Top Sales' },
            series: [{
              name: 'Top Sales',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.sdayTopSaleData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })
        }


        var chart_7bardv = this.$refs.chart_sday_todaysales;
        if (chart_7bardv) {

          this.sdayTopSalesChart = echarts.init(chart_7bardv, 'macarons')

          this.sdayTopSalesChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

          this.sdayTopSalesChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              type: 'scroll',
              orient: 'vertical',
              right: 10,
              top: 20,
              bottom: 20,
              data: this.sdayTopOrderNameData
            },
            title: { text: 'Top Orders' },
            series: [{
              name: 'Top Orders',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.sdayTopOrderData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }

        var chart_7linedv = this.$refs.chart_sday_order;
        if (chart_7linedv) {

          this.sdayOrderChart = echarts.init(chart_7linedv, 'macarons')

          this.sdayOrderChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

          this.sdayOrderChart.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            // title: { text: 'Daily Order' },
            grid: {
              top: 48,
              left: '2%',
              right: '2%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: this.sdayOrderDateData,
              axisTick: {
                alignWithLabel: true
              }
            }],
            yAxis: [{
              type: 'value',
              axisTick: {
                show: false
              }
            }],
            series: [{
              name: 'Daily Order',
              type: 'bar',
              stack: 'vistors',
              barWidth: '60%',
              data: this.sdayOrderData,
              animationDuration
            }
            ]

          })


        }

        //30d

        var chart_30dv = this.$refs.chart_tday_toporder;
        if (chart_30dv) {

          this.tdayTopOrderChart = echarts.init(chart_30dv, 'macarons')

          this.tdayTopOrderChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

          this.tdayTopOrderChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              // left: 'center',
              // bottom: '10',
              orient: 'vertical',
              left: 'right',
              data: this.tdayTopSaleNameData
            },
            title: { text: 'Top Sales' },
            series: [{
              name: 'Top Sales',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.tdayTopSaleData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })
        }


        var chart_30bardv = this.$refs.chart_tday_todaysales;
        if (chart_30bardv) {

          this.tdayTopSalesChart = echarts.init(chart_30bardv, 'macarons')

          this.tdayTopSalesChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

          this.tdayTopSalesChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              type: 'scroll',
              orient: 'vertical',
              right: 10,
              top: 20,
              bottom: 20,
              data: this.tdayTopOrderNameData
            },
            title: { text: 'Top Orders' },
            series: [{
              name: 'Top Orders',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.tdayTopOrderData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }

        var chart_30linedv = this.$refs.chart_tday_order;
        if (chart_30linedv) {

          this.tdayOrderChart = echarts.init(chart_30linedv, 'macarons')

          this.tdayOrderChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

          this.tdayOrderChart.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            // title: { text: 'Daily Order' },
            grid: {
              top: 48,
              left: '2%',
              right: '2%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: this.tdayOrderDateData,
              axisTick: {
                alignWithLabel: true
              }
            }],
            yAxis: [{
              type: 'value',
              axisTick: {
                show: false
              }
            }],
            series: [{
              name: 'Daily Order',
              type: 'bar',
              stack: 'vistors',
              barWidth: '60%',
              data: this.tdayOrderData,
              animationDuration
            }
            ]

          })


          //exp
          var chart_exp_week = this.$refs.chart_exp_week;
          if (chart_exp_week) {

            this.expWeekChart = echarts.init(chart_exp_week, 'macarons')

            this.expWeekChart.showLoading({
                text: 'No data available',
                color: '#ffffff',
                textColor: '#8a8e91',
                maskColor: 'rgba(255, 255, 255, 0.8)',
                textStyle : {
                  fontSize : 50
                }
              }
          );

            this.expWeekChart.setOption({
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'shadow'
                }
              },
              title: { text: 'Daily Event' },
              grid: {
                top: 36,
                left: '2%',
                right: '2%',
                bottom: '3%',
                containLabel: true
              },
              xAxis: [{
                type: 'category',
                data: this.expWeekDateData,
                axisTick: {
                  alignWithLabel: true
                }
              }],
              yAxis: [{
                type: 'value',
                axisTick: {
                  show: false
                }
              }],
              series: [{
                name: 'Daily Event',
                type: 'bar',
                stack: 'vistors',
                barWidth: '60%',
                data: this.expWeekData,
                animationDuration
              }
              ]
            })

          }


        }


      },
      setPieOptions(showdata) {

        if (this.todayTopSaleNameData == undefined || this.todayTopSaleNameData.length <=0){

        }else {

          this.todayTopOrderChart.hideLoading()
          this.todayTopOrderChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/> {b} : {c} ({d}%)'
            },
            legend: {
              // left: 'center',
              // bottom: '10',
              orient: 'vertical',
              left: 'right',
              data: this.todayTopSaleNameData
            },
            series: [{
              name: 'Top Sales',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.todayTopSaleData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }


        if (this.todayTopOrderData == undefined || this.todayTopOrderData.length <=0){

        }else {

          this.todayTopSalesChart.hideLoading()
          this.todayTopSalesChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              // left: 'center',
              // bottom: '10',
              orient: 'vertical',
              left: 'right',
              data: this.todayTopOrderNameData
            },
            title: { text: 'Top Orders' },
            series: [{
              name: 'Top Orders',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.todayTopOrderData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }


        if (this.todayRealTimeOrderData == undefined || this.todayRealTimeOrderData.length <=0){

        }else {

          this.todayOrderChart.hideLoading()
          this.todayOrderChart.setOption({
            tooltip: {
              trigger: 'axis',
              formatter: function (params) {
                params = params[0];
                var date = new Date(params.name);
                var hour = date.getHours();
                var minutes = date.getMinutes();
                if(hour < 10){
                  hour = '0' + hour;
                }
                if(minutes < 10){
                  minutes = '0' + minutes;
                }
                var dateStr = hour + ':' + minutes;
                return dateStr + ' ' + params.value[1];
              },
              axisPointer: {
                animation: false
              }
            },
            xAxis: {
              type: 'time',
              splitLine: {
                show: false
              },
              splitNumber:12,
              axisLine:{
                lineStyle:{
                  color: '#1B2232'
                }
              },
              axisLabel:{
                formatter:function(value,index){
                  var date = new Date(value);
                  var hour = date.getHours();
                  var minutes = date.getMinutes();
                  if(hour < 10){
                    hour = '0' + hour;
                  }
                  if(minutes < 10){
                    minutes = '0' + minutes;
                  }
                  return hour + ':' + minutes;
                },
                color:'#1B2232'
              }
            },
            yAxis: {
              name:'Order',
              nameTextStyle:{
                color: '#1B2232',
                fontSize:15
              },
              minInterval: 1,
              type: 'value'
            },
            series: [{
              name: '',
              type: 'bar',
              hoverAnimation: false,
              smooth: true,
              symbolSize: 4,
              data: this.todayRealTimeOrderData

            }],
            color: ['#88d8b0','#88d8b0','#5bc0de','#5cb85c','#428bca','#ffcc5c'],

            title: { text: 'Today Orders' }
          })

        }



        //7d

        if (this.sdayTopSaleData == undefined || this.sdayTopSaleData.length <=0){

        }else {

          this.sdayTopOrderChart.hideLoading()
          this.sdayTopOrderChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/> {b} : {c} ({d}%)'
            },
            legend: {
              // left: 'center',
              // bottom: '10',
              orient: 'vertical',
              left: 'right',
              data:  this.sdayTopSaleNameData
            },
            series: [{
              name: 'Top Sales',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.sdayTopSaleData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }

        if (this.sdayTopOrderData == undefined || this.sdayTopOrderData.length <=0){

        }else {

          this.sdayTopSalesChart.hideLoading()
          this.sdayTopSalesChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              orient: 'vertical',
              left: 'right',
              data: this.sdayTopOrderNameData
            },
            title: { text: 'Top Orders' },
            series: [{
              name: 'Top Orders',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              data: this.sdayTopOrderData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }


        if (this.sdayOrderData == undefined || this.sdayOrderData.length <=0){

        }else {

          this.sdayOrderChart.hideLoading()
          this.sdayOrderChart.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              data:['Number of Order', 'Order Amount (Total)','Order Amount (Average)','7-day Order Moving Average','7-day Sales Moving Average']
            },
            color: ['#d11141','#00b159','#00aedb','#f37735','#ffc425'],
            // title: { text: 'Daily' },
            grid: {
              top: 48,
              left: '2%',
              right: '2%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                data: this.sdayOrderDateData,
                axisTick: {
                  alignWithLabel: true
                }
              }],
            yAxis: [{
              type: 'value',
              axisTick: {
                show: false
              }
            }],
            series: [{
              name: 'Number of Order',
              type: 'line',
              smooth: true,
              data: this.sdayOrderData,
              animationDuration
            },{
              name: 'Order Amount (Total)',
              type: 'line',
              smooth: true,
              data: this.sdaySalesData,
              animationDuration
            },{
              name: 'Order Amount (Average)',
              type: 'line',
              smooth: true,
              data: this.sdaySalesAvgData,
              animationDuration
            },{
              name: '7-day Order Moving Average',
              type: 'line',
              smooth: true,
              data: this.sdayOrderMAData,
              animationDuration
            }
              ,{
                name: '7-day Sales Moving Average',
                type: 'line',
                smooth: true,
                data: this.sdaySalesMAData,
                animationDuration
              }
            ]

          })

        }



        //30d

        if (this.tdayTopSaleData == undefined || this.tdayTopSaleData.length <=0){

        }else {

          this.tdayTopOrderChart.hideLoading()
          this.tdayTopOrderChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/> {b} : {c} ({d}%)'
            },
            legend: {
              // left: 'center',
              // bottom: '10',
              orient: 'vertical',
              left: 'right',
              data:  this.tdayTopSaleNameData
            },
            series: [{
              name: 'Top Sales',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              // roseType: 'radius',
              // radius: [15, 95],
              // center: ['50%', '38%'],
              data: this.tdayTopSaleData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }

        if (this.tdayTopOrderData == undefined || this.tdayTopOrderData.length <=0){

        }else {

          this.tdayTopSalesChart.hideLoading()

          this.tdayTopSalesChart.setOption({
            tooltip: {
              trigger: 'item',
              formatter: '{a} <br/>{b} : {c} ({d}%)'
            },
            legend: {
              orient: 'vertical',
              left: 'right',
              data: this.tdayTopOrderNameData
            },
            title: { text: 'Top Orders' },
            series: [{
              name: 'Top Orders',
              type: 'pie',
              radius: '55%',
              center: ['50%', '65%'],
              data: this.tdayTopOrderData,
              animationEasing: 'cubicInOut',
              animationDuration: 2600,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }]
          })

        }


        if (this.tdayOrderDateData == undefined || this.tdayOrderDateData.length <=0){

        }else {

          this.tdayOrderChart.hideLoading()

          this.tdayOrderChart.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              data:['Number of Order', 'Order Amount (Total)','Order Amount (Average)','30-day Order Moving Average','30-day Sales Moving Average']
            },
            // title: { text: 'Daily' },
            grid: {
              top: 48,
              left: '2%',
              right: '2%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                data: this.tdayOrderDateData,
                axisTick: {
                  alignWithLabel: true
                }
              }],
            yAxis: [{
              type: 'value',
              axisTick: {
                show: false
              }
            }],
            series: [{
              name: 'Number of Order',
              type: 'line',
              smooth: true,
              data: this.tdayOrderData,
              animationDuration
            },{
              name: 'Order Amount (Total)',
              type: 'line',
              smooth: true,
              data: this.tdaySalesData,
              animationDuration
            },{
              name: 'Order Amount (Average)',
              type: 'line',
              smooth: true,
              data: this.tdaySalesAvgData,
              animationDuration
            },{
              name: '30-day Order Moving Average',
              type: 'line',
              smooth: true,
              data: this.tdayOrderMAData,
              animationDuration
            }
              ,{
                name: '30-day Sales Moving Average',
                type: 'line',
                smooth: true,
                data: this.tdaySalesMAData,
                animationDuration
              }
            ]

          })

        }



        //exp

        if (this.expWeekData == undefined || this.expWeekData.length <=0){

        }else {

          this.expWeekChart.hideLoading()

          this.expWeekChart.setOption({
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            title: { text: 'Top Order Days of Week' },
            grid: {
              top: 36,
              left: '2%',
              right: '2%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [{
              type: 'category',
              data: this.expWeekDateData,
              axisTick: {
                alignWithLabel: true
              }
            }],
            yAxis: [{
              type: 'value',
              axisTick: {
                show: false
              }
            }],
            series: [{
              name: 'Top Order Days of Week',
              type: 'bar',
              stack: 'vistors',
              barWidth: '60%',
              data: this.expWeekData,
              animationDuration
            }
            ]
          })

        }


      },
      /* eslint-enable */
    /* eslint-disable */
      logout() {
        localStorage.clear();
        sessionStorage.clear();
        this.$store.commit('clearToken');
        this.$router.push('/reportLogin')
      },
      loadEvents() {
        var that = this;
        this.$axios.get('/api/event_feed/reportToday/', {
          params: {},
          headers: { 'Authorization': 'JWT ' + this.$store.state.token }
        })
            .then((res) => {
              var result = res.data;


              if (res.status == 200) {

                if (result['msg']=='success'){

                  this.todayTopSaleData = result['result']['sales_data']
                  this.todayTopSaleNameData = result['result']['sales_name']
                  this.todayTopOrderData = result['result']['order_data']
                  this.todayTopOrderNameData = result['result']['order_name']
                  this.todayRealTimeOrderData = result['result']['order_realtime']
                  this.todayTitle = 'Today ('+result['result']['date']+")"
                  this.todaySum = result['result']['sales']
                  this.todayAvgSum = result['result']['sales_avg']
                  this.todayOrder = result['result']['order']

                  this.seriesData.push([
                    { value: 123, name: 'test' }
                  ]);
                  // this.reload()

                }else {

                  // alert(result['msg'])
                }


              } else {
                alert('Login timed out, please log in again')
                that.logout()
              }
            }).catch(function(arg) {
            alert('Unknown Error, please try again : '+arg)
          that.logout()

        })
      },
      load7Days() {
        var that = this;
        this.$axios.get('/api/event_feed/report7Days/', {
          params: {},
          headers: { 'Authorization': 'JWT ' + this.$store.state.token }
        })
            .then((res) => {
              var result = res.data;


              if (res.status == 200) {

                if (result['msg']=='success'){

                  this.sdayTopSaleData = result['result']['sales_data']
                  this.sdayTopSaleNameData = result['result']['sales_name']
                  this.sdayTopOrderData = result['result']['order_data']
                  this.sdayTopOrderNameData = result['result']['order_name']
                  this.sdayOrderData = result['result']['daily_order_data']
                  this.sdaySalesData = result['result']['daily_sales_data']
                  this.sdaySalesAvgData = result['result']['daily_sales_avg_data']
                  this.sdaySalesMAData = result['result']['daily_sales_ma_data']
                  this.sdayOrderMAData = result['result']['daily_order_ma_data']
                  this.sdayOrderDateData = result['result']['daily_order_date']
                  this.sdayTitle = 'Last 7 Days ('+result['result']['date']+")"
                  this.sdaySum = result['result']['sales']
                  this.sdayOrder = result['result']['order']
                  this.sdayAvgSum = result['result']['sales_avg']
                  this.sdayAvgOrder = result['result']['order_avg']

                  this.seriesData.push([
                    { value: 123, name: 'test' }
                  ]);

                  // this.reload()

                }else {

                  // alert(result['msg'])
                }


              } else {
                alert('Login timed out, please log in again')
                that.logout()
              }
            }).catch(function(arg) {
          alert('Unknown Error, please try again : '+arg)
          that.logout()

        })
      },
      load30Days() {
        var that = this;
        this.$axios.get('/api/event_feed/report30Days/', {
          params: {},
          headers: { 'Authorization': 'JWT ' + this.$store.state.token }
        })
            .then((res) => {
              var result = res.data;


              if (res.status === 200) {

                if (result['msg']==='success'){

                  this.tdayTopSaleData = result['result']['sales_data']
                  this.tdayTopSaleNameData = result['result']['sales_name']
                  this.tdayTopOrderData = result['result']['order_data']
                  this.tdayTopOrderNameData = result['result']['order_name']
                  this.tdayOrderData = result['result']['daily_order_data']
                  this.tdaySalesData = result['result']['daily_sales_data']
                  this.tdaySalesAvgData = result['result']['daily_sales_avg_data']
                  this.tdaySalesMAData = result['result']['daily_sales_ma_data']
                  this.tdayOrderMAData = result['result']['daily_order_ma_data']
                  this.tdayOrderDateData = result['result']['daily_order_date']
                  this.tdayTitle = 'Last 30 Days ('+result['result']['date']+")"
                  this.tdaySum = result['result']['sales']
                  this.tdayOrder = result['result']['order']
                  this.tdayAvgSum = result['result']['sales_avg']
                  this.tdayAvgOrder = result['result']['order_avg']

                  this.seriesData.push([
                    { value: 123, name: 'test' }
                  ]);

                  // this.reload()

                }else {

                  // alert(result['msg'])
                }


              } else {
                alert('Login timed out, please log in again')
                that.logout()
              }
            }).catch(function(arg) {
          alert('Unknown Error, please try again : '+arg)
          that.logout()

        })
      },
      loadExp() {
        var that = this;
        this.$axios.get('/api/event_feed/reportExploreData/', {
          params: {},
          headers: { 'Authorization': 'JWT ' + this.$store.state.token }
        })
            .then((res) => {
              var result = res.data;


              if (res.status === 200) {

                if (result['msg']=='success'){

                  this.expWeekData = result['result']['day_week_data']
                  this.expWeekDateData = result['result']['day_week_date']


                  this.seriesData.push([
                    { value: 123, name: 'test' }
                  ]);

                  // this.reload()

                }else {

                  // alert(result['msg'])
                }


              } else {
                alert('Login timed out, please log in again')
                that.logout()
              }
            }).catch(function(arg) {
          alert('Unknown Error, please try again : '+arg)
          that.logout()

        })
      },
      loadStock() {
        var that = this;
        this.$axios.get('/api/event_feed/reportStockData/', {
          params: {},
          headers: { 'Authorization': 'JWT ' + this.$store.state.token }
        })
            .then((res) => {
              var result = res.data;


              if (res.status == 200) {

                if (result['msg']=='success'){

                  this.stockList = result['result']['stocklist']

                  this.stockDate = '('+result['result']['date']+')'


                  this.seriesData.push([
                    { value: 123, name: 'test' }
                  ]);
                  // this.reload()

                }else {

                  // alert(result['msg'])
                }


              } else {
                alert('Login timed out, please log in again')
                that.logout()
              }
            }).catch(function(arg) {
            alert('Unknown Error, please try again : '+arg)
          that.logout()

        })
      },
      lowStockNotice() {
        var that = this;
        this.$axios.request({
                                url: '/api/event_feed/lowStockNotice/',
                                method: 'POST',
                                data: { mail: this.noticeemail+''},
                                headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                            }).then((res) => {
              var result = res.data;


              if (res.status == 200) {

                that.$message({
                    type: 'success',
                    message: result['msg']
                  });

              } else {
                alert('Login timed out, please log in again')
                // that.logout()
              }
            }).catch(function(arg) {
                alert('Unknown Error, please try again : '+arg)
                // that.logout()

        })
      },
      inputEmail() {
          this.$prompt('Please input your email', 'Low Stock Notice', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
            inputErrorMessage: 'wrong email format'
          }).then(({ value }) => {

            this.noticeemail = value

            this.lowStockNotice()

          });
        },
      exportdata() {
                var that = this;
                this.btnName = 'Loading data, please waiting, do not refresh page...'
                this.btnLoading = true
                // this.$axios.post('/api/event_feed/exportData/', {
                //                 params: {
                //                     startdate:this.startDate,
                //                     enddate:this.endDate
                //                 },
                //                 responseType: 'blob',
                //                 headers: { 'Authorization': 'JWT ' + this.$store.state.token,'Content-Type': 'application/json' }
                //             })
                    this.$axios.request({
                                url: '/api/event_feed/salesPeriodData/',
                                method: 'GET',
                                params: {
                                    startdate:this.startDate,
                                    enddate:this.endDate
                                },
                                headers: { 'Authorization': 'JWT ' + this.$store.state.token,'Content-Type': 'application/x-www-form-urlencoded' }
                            })
                            .then((res) => {


                                var result = res.data;

                                if (res.status === 200) {

                                  if (result['msg']==='success'){

                                    var sales_info = that.$refs.sale_period_data_result;
                                    sales_info.style.display = "";


                                    this.periodDaySum = result['result']['sales']
                                    this.periodDayOrder = result['result']['order']
                                    this.periodDayAvgSum = result['result']['sales_avg']
                                    this.periodDayAvgOrder = result['result']['order_avg']
                                    this.periodDaySalesOrderAvg = result['result']['sales_order_avg']

                                    this.seriesData.push([
                                      { value: 123, name: 'test' }
                                    ]);

                                    // this.reload()

                                  }else {

                                    var sales_info = this.$refs.sale_period_data_result;
                                    sales_info.style.display = "none";

                                    // alert(result['msg'])
                                  }


                                } else {
                                  alert('Login timed out, please log in again')
                                  that.logout()
                                }


                                this.btnName = 'Get Data'

                                this.btnLoading = false

                            }).catch(function(arg) {
                                alert('Unknown Error, please try again : '+arg)

                                this.btnName = 'Get Data'
                                this.btnLoading = false
                                that.logout()


                            })
            },
    }

  }
  </script>

  <style scoped>
  h1,
  h2 {
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }

  .el-table .warning-row {
    background: oldlace;
  }
  </style>
