import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'vue-cookies'  // 引入cookie,npm install vue-cookies --save

Vue.use(Vuex);

export default new Vuex.Store({
    // 组件中通过 this.$store.state.username 调用
    state: {
        // 默认去cookie中取值
        username: Cookie.get("username"),
        token: Cookie.get("token"),
    },
    mutations: {
        // 组件中通过this.$store.commit(函数名, 参数)调用
        saveToken: function (state, userToken) {
            state.username = userToken.username;
            state.token = userToken.token;
            Cookie.set("username", userToken.username, "30min");
            Cookie.set("token", userToken.token, "30min");
        },
        clearToken: function (state) {
          state.username = null;
          state.token = null;
          Cookie.remove("username");
          Cookie.remove("token");
        },
    }
})