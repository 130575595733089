<template>
    <div>
        <div class="login">
    
            <h2>Console</h2>
    
            <el-form label-width="80px">
                <el-form-item label="">
                    <el-input v-model="username" placeholder="User Name"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="password" placeholder="Password" type="password"></el-input>
                </el-form-item>
                <el-button type="primary" @click="doLogin">Login</el-button>
            </el-form>
        </div>
    </div>
</template>
 
<script>
export default {
    data() {
        return {

            username: '',
            password: ''
        }
    },
    methods: {
        /* eslint-disable */
        doLogin() {
            var that = this;
            this.$axios.request({
                url: '/api/api-token-auth/',
                method: 'POST',
                data: {
                    username: this.username,
                    password: this.password
                },
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(function(arg) {

                // console.log(arg.status)
                if (arg.status === 200) {

                    that.$store.commit('saveToken', { token: arg.data.token, username: that.username });
                    that.$router.push('/consoleDashboard')
                } else {
                    alert(arg.data.error)
                }
            }).catch(function() {

                // console.log(arg)
            })
        }
        /* eslint-enable */
    }
}
</script>

<style scoped>
.login {
    width: 50%;
    margin: 0 auto;
}
</style>