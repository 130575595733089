<template>
  <div class="home">
    <HelloWorld msg="Security Console" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/ConsoleLogin.vue";

export default {
  name: "ConsoleLogin",
  components: {
    HelloWorld
  }
};
</script>
