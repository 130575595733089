import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store"
import axios from "axios";
import VueClipboard from 'vue-clipboard2'
// import VueResource from 'vue-resource'
import Element from "element-ui"
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false;
Vue.use(Element)
Vue.use( VueClipboard )
// Vue.use(VueResource)
Vue.prototype.$axios = axios;
// axios.defaults.baseURL ='http://0.0.0.0:8081';
axios.defaults.withCredentials=true;

router.beforeEach(function (to, from, next) {
	document.title = 'Console'
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.meta.requireAuth) {
        // 当前要去的url只有登录后才能访问
        if (store.state.token) {
            // token为true表示可以继续访问
            next()
        } else {
            // token不为true跳转到登录页面
            if (to.meta.title == 'Sales Report') {
                next({path:'/reportLogin',})
            }else if (to.meta.title == 'Dashboard'){
                next({path:'/consoleLogin',})
            }else if (to.meta.title == 'Coupon Dashboard'){
                next({path:'/couponLogin',})
            }else if (to.meta.title == 'Phishing Site List Console'){
                next({path:'/PhishingSiteLogin',})
            }else if (to.meta.title == 'Smartone Console'){
                next({path:'/SMTLogin',})
            }else {
                next({path:'/',})
            }

        }
    } else {
        // url不需要访问即可以访问
        next()
    }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");


