<template>
  <div>
<!--    <div class="login">-->

<!--      <el-image :src="require('@/assets/pokpong_logo.png')" style="width: 180px; height: 180px" />-->
<!--      <h2>PokPong Mobile Security Console</h2>-->

<!--      <el-form label-width="80px">-->
<!--        <el-form-item label="">-->
<!--          <el-input v-model="username" placeholder="User Name"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="">-->
<!--          <el-input-->
<!--            v-model="password"-->
<!--            placeholder="Password"-->
<!--            type="password"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
<!--        <el-button type="primary" @click="doLogin">Login</el-button>-->
<!--      </el-form>-->
<!--    </div>-->

    <div class="container">
    <h1>Page Not Found</h1>
    <p>Sorry, the page you are looking for cannot be found.</p>
    <p>Please check the URL and try again. If you believe this is an error, please <router-link to="/">contact us</router-link>.</p>
  </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // username: "",
      // password: ""
    };
  },
  methods: {
    /* eslint-disable */
        // doLogin() {
        //     var that = this;
        //     this.$axios.request({
        //         url: '/api/api-token-auth/',
        //         method: 'POST',
        //         data: {
        //             username: this.username,
        //             password: this.password
        //         },
        //         headers: {
        //             'Content-Type': 'application/json'
        //         }
        //     }).then(function(arg) {
        //
        //         // console.log(arg.status)
        //         if (arg.status === 200) {
        //
        //             that.$store.commit('saveToken', { token: arg.data.token, username: that.username });
        //             that.$router.push('/data')
        //         } else {
        //             alert(arg.data.error)
        //         }
        //     }).catch(function() {
        //
        //         // console.log(arg)
        //     })
        // }
        /* eslint-enable */
  }
};
</script>

<style scoped>
.login {
  width: 50%;
  margin: 0 auto;
}
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  margin-top: 50px;
}

h1 {
  font-size: 48px;
  margin-top: 0;
  color: #333;
}

p {
  font-size: 24px;
  color: #555;
}

a {
  color: #007bff;
}
</style>
