import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Data from "../views/Data.vue";
import ExportData from "../views/ExportData.vue";
import SalesKeyword from "../views/SalesKeyword.vue";
import SalesReport from "../views/SalesReport.vue";
import ReportLogin from "../views/ReportLogin.vue";
import ConsoleLogin from "../views/ConsoleLogin.vue";
import ConsoleDashboard from "../views/ConsoleDashboard.vue";
import CouponLogin from "../views/CouponLogin.vue";
import CouponDashboard from "../views/CouponDashboard.vue";
import PhishingSiteList from "../views/phishingSiteList.vue";
import PhishingSiteLogin from "../views/PhishingSiteListLogin.vue";
import PhishingSiteConsole from "../views/PhishingSiteListDashboard.vue";
import SMTLogin from "../views/SMTLogin.vue";
import SMTConsole from "../views/SMTConsole.vue";
import testPage from "@/views/testPage.vue";
Vue.use(VueRouter);

// eslint-disable-next-line no-sparse-arrays
const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      title: "Home"
    },
    component: Home
  },
  {
    path: "/data",
    name: "ShowData",
    meta: {
      requireAuth: true,
      title: "Home"
    },
    component: Data
  },
  {
    path: "/export",
    name: "exportData",
    meta: {
      requireAuth: true
    },
    component: ExportData
  },
  {
    path: "/salesReport",
    name: "salesReport",
    meta: {
      title: "Sales Report",
      requireAuth: true
    },
    component: SalesReport
  },
  {
    path: "/reportLogin",
    name: "reportLogin",
    meta: {
      title: "Sales Report"
    },
    component: ReportLogin
  },
  {
    path: "/consoleDashboard",
    name: "consoleDashboard",
    meta: {
      title: "Dashboard",
      requireAuth: true
    },
    component: ConsoleDashboard
  },
  {
    path: "/consoleLogin",
    name: "consoleLogin",
    meta: {
      title: "Security Console"
    },
    component: ConsoleLogin
  },
  {
    path: "/coupon",
    name: "couponDashboard",
    meta: {
      title: "Coupon Dashboard",
      requireAuth: true
    },
    component: CouponDashboard
  },
  {
    path: "/couponLogin",
    name: "couponLogin",
    meta: {
      title: "Coupon Login"
    },
    component: CouponLogin
  },
  ,
  {
    path: "/PhishingSiteConsole",
    name: "PhishingSiteConsole",
    meta: {
      title: "Phishing Site List Console",
      requireAuth: true
    },
    component: PhishingSiteConsole
  },
  {
    path: "/PhishingSiteLogin",
    name: "PhishingSiteLogin",
    meta: {
      title: "Phishing Site List Logins"
    },
    component: PhishingSiteLogin
  },
  {
    path: "/saleskeyword",
    name: "salesKeyword",
    meta: {
      title: "Sales Keywords"
    },
    component: SalesKeyword
  },
  {
    path: "/phishingSiteList",
    name: "phishingSiteList",
    meta: {
      title: "Phishing Site List"
    },
    component: PhishingSiteList
  },
  {
    path: "/testDeeplinksPage",
    name: "testDeeplinksPage",
    meta: {
      title: "Test Deeplinks Page"
    },
    component: testPage
  },
  {
    path: "/SMTConsole",
    name: "SmartoneConsole",
    meta: {
      title: "Smartone Console"
      // requireAuth: true,
    },
    component: SMTConsole,
    children: [
      {
        path: "/SMTConsole/urllist",
        component: () => import("../components/SMTConsoleURL.vue"),
        name: "URLList"
      },
      {
        path: "/SMTConsole/smslist",
        component: () => import("../components/SMTConsoleSMS.vue"),
        name: "SMSList"
      },
      {
        path: "/SMTConsole/senderlist",
        component: () => import("../components/SMTConsoleSender.vue"),
        name: "SenderList"
      }
    ]
  },
  {
    path: "/SMTLogin",
    name: "SmartoneConsoleLogin",
    meta: {
      title: "Smartone Console Login"
    },
    component: SMTLogin
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
