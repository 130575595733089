<template>
  <div>
    <div class="show_data">
      <p align="left">

          <el-row :gutter="20" align="left">
        <el-col :span="1.05"><el-image
            :src="require('@/assets/pokpong_logo.png')"
            style="width: 81px; height: 81px"
          /></el-col>
        <el-col :span="1"><el-button type="text" style="height: 81px;font-size: 48px;color: #666666;"
            >PokPong Mobile Security Console</el-button
          ></el-col>
        </el-row>
      </p>
      <div v-if="this.$store.state.token">
        <p align="right">
          <el-row align="right">
            <el-button
              type="primary"
              @click.native="exportdata()"
              icon="el-icon-download"
              >Export Data</el-button
            >
            <el-button @click.native="logout()" icon="el-icon-user-solid">{{
              this.$store.state.username
            }}</el-button>
          </el-row>
        </p>
      </div>
      <div v-else>
        <p align="right">
          <router-link to="/" align="right">Login</router-link>
        </p>
      </div>
<!--      <h2>PokPong Mobile Security Console</h2>-->

      <div style="display:flex;margin-top: 64px ">
        <div
          ref="chart_platform_version"
          class="chart_bar"
          style=" height:500px; width:33.33333%;margin-right:20px;margin-top: 36px"
        ></div>
        <div
          ref="chart_platform"
          class="chart_bar"
          style="height:500px;width:33.33333%;margin-right:20px"
        ></div>
        <div
          ref="chart_platform_daily"
          class="chart_bar"
          style="height:500px; width:33.33333%; margin-right:20px"
        ></div>
      </div>
      <div style="display:flex; ">
        <div
          ref="chart_pie"
          class="chart_pie"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>
        <div
          ref="chart_line"
          class="chart_line"
          style="height:500px; width:33.33333%; margin-right:20px"
        ></div>
        <div
          ref="chart_bar"
          class="chart_bar"
          style=" height:500px; width:33.33333%;margin-right:20px"
        ></div>
      </div>
      <el-row>
        <el-table
          :data="eventList"
          default-text="N/A"
          style="width: 100%"
          border
          stripe
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="event_Type" label="Event" min-width="100">
          </el-table-column>
          <el-table-column prop="deviceId" label="Device" min-width="100">
          </el-table-column>
          <el-table-column prop="deviceTime" label="Time" min-width="100">
          </el-table-column>
          <el-table-column
            prop="assessments[0].classification"
            :formatter="formatIssue"
            default-text="N/A"
            label="Issue"
            min-width="100"
          >
          </el-table-column>
          <el-table-column fixed="right" label="Action" min-width="100">
            <template slot-scope="scope">
              <el-button @click="handleViewJsonClick(scope.row)" type="text"
                >View JSON</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!-- <el-table :data="eventList">
                    <el-table-column
                    v-for="{ prop, label } in colConfigs"
                    :key="prop"
                    :prop="prop"
                    :label="label">
                    </el-table-column>
                </el-table> -->
      </el-row>

      <el-dialog
        title="View JSON"
        :visible.sync="dialogFormVisible"
        @close="dialogFormVisible = false"
        center
      >
        <span>{{ this.input }}</span>

        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleViewJsonCancelClick()"
            >OK</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
// import echarts from 'echarts'
var echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
require("echarts/lib/chart/pie");

require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
// require("echarts/lib/component/dataZoom");
// require("echarts/lib/component/markPoint");
// require("echarts/lib/component/markLine");
require("echarts/theme/macarons"); // echarts theme
const animationDuration = 6000;
export default {
  name: "show_data",
  inject: ["reload"],
  data() {
    this.colConfigs = [
      { prop: "type", label: "Event" },
      { prop: "deviceId", label: "Device" },
      { prop: "deviceTime", label: "Time" }
    ];
    return {
      dialogFormVisible: false,
      input: "",
      timer: "",
      eventList: [],
      pieChart: null,
      lineChart: null,
      barChart: null,
      platformDailyChart: null,
      platformVersionChart: null,
      platformChart: null,
      seriesData: [],
      platformDailyDate: [],
      platformDailyAndroidData: [],
      platformDailyiOSData: [],
      platformVersionName: [],
      platformVersionData: [],
      platformData: [],
      eventRecentChartDate: [],
      eventDayCountChartData: [],
      eventDAUChartData: [],
      eventTypeChartData: [],
      eventTypeChartName: [],
      appDetectionChartData: [],
      appDetectionChartName: []
    };
  },
  mounted: function() {
    this.loadEvents();
    this.timer = setInterval(this.loadEvents, 60000);
    this.$nextTick(() => {
      this.initPieCharts();
    });
    setTimeout(() => {
      this.seriesData.push([{ value: 123, name: "test" }]);
    }, 1000);
  },
  beforeDestroy() {
    if (!this.pieChart) {
      return;
    }
    this.pieChart.dispose();
    this.pieChart = null;

    if (!this.lineChart) {
      return;
    }
    this.lineChart.dispose();
    this.lineChart = null;

    if (!this.barChart) {
      return;
    }
    this.barChart.dispose();
    this.barChart = null;
    clearInterval(this.timer);
  },
  watch: {
    seriesData(val) {
      this.setPieOptions(val);
    },
    deep: true
  },
  methods: {
    /* eslint-disable */
                initPieCharts() {

                    var chart_dv = this.$refs.chart_pie;
                    if (chart_dv) {

                        this.pieChart = echarts.init(chart_dv, 'macarons')
                        this.pieChart.setOption({
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b} : {c} ({d}%)'
                            },
                            legend: {
                                // left: 'center',
                                // bottom: '10',
                                orient: 'vertical',
                                left: 'right',
                                data: this.eventTypeChartName
                            },
                            title: { text: 'Event Type' },
                            series: [{
                                name: 'Event Type',
                                type: 'pie',
                                radius: '55%',
                                center: ['50%', '65%'],
                                // roseType: 'radius',
                                // radius: [15, 95],
                                // center: ['50%', '38%'],
                                data: this.eventTypeChartData,
                                animationEasing: 'cubicInOut',
                                animationDuration: 2600
                            }]
                        })
                    }


                    var chart_bardv = this.$refs.chart_bar;
                    if (chart_bardv) {

                        this.barChart = echarts.init(chart_bardv, 'macarons')

                        this.barChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            title: { text: 'Daily Event' },
                            grid: {
                                top: 36,
                                left: '2%',
                                right: '2%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: [{
                                type: 'category',
                                data: this.eventRecentChartDate,
                                axisTick: {
                                    alignWithLabel: true
                                }
                            }],
                            yAxis: [{
                                type: 'value',
                                axisTick: {
                                    show: false
                                }
                            }],
                            series: [{
                                    name: 'Daily Event',
                                    type: 'bar',
                                    stack: 'vistors',
                                    barWidth: '60%',
                                    data: this.eventDayCountChartData,
                                    animationDuration
                                }
                                // , {
                                //   name: 'pageB',
                                //   type: 'bar',
                                //   stack: 'vistors',
                                //   barWidth: '60%',
                                //   data: [80, 52, 200, 334, 390, 330, 220],
                                //   animationDuration
                                // }, {
                                //   name: 'pageC',
                                //   type: 'bar',
                                //   stack: 'vistors',
                                //   barWidth: '60%',
                                //   data: [30, 52, 200, 334, 390, 330, 220],
                                //   animationDuration
                                // }
                            ]
                        })

                    }

                    var chart_platformDailydv = this.$refs.chart_platform_daily;
                    if (chart_platformDailydv) {

                        this.platformDailyChart = echarts.init(chart_platformDailydv, 'macarons')

                        this.platformDailyChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'right'
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            title: { text: 'Daily Active Device' },
                            // grid: {
                            //     top: 100,
                            //     left: '2%',
                            //     right: '2%',
                            //     bottom: 100,
                            //     containLabel: true
                            // },
                            color: ['#3DDC84','#c0c0c0'],
                            xAxis: [{
                                type: 'category',
                                data: this.platformDailyDate,
                                axisTick: {
                                    alignWithLabel: true
                                }
                            }],
                            yAxis: [{
                                type: 'value',
                                axisTick: {
                                    show: false
                                }
                            }],
                            series: [{
                                    name: 'Android Active Device',
                                    type: 'bar',
                                    barWidth: '60%',
                                    data: this.platformDailyAndroidData,
                                    animationDuration
                                },
                                {
                                    name: 'iOS Active Device',
                                    type: 'bar',
                                    barWidth: '60%',
                                    data: this.platformDailyiOSData,
                                    animationDuration
                                }
                            ]
                        })

                    }

                    var chart_platformdv = this.$refs.chart_platform;
                    if (chart_platformdv) {

                        this.platformChart = echarts.init(chart_platformdv, 'macarons')

                        this.platformChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            title: { text: 'Daily Active Device' },
                            // grid: {
                            //     top: 100,
                            //     left: '2%',
                            //     right: '2%',
                            //     bottom: 100,
                            //     containLabel: true
                            // },
                            yAxis: [{
                                type: 'category',
                                data: ['Android','iOS'],
                                axisTick: {
                                    alignWithLabel: true
                                }
                            }],
                            xAxis: [{
                                type: 'value',
                                axisTick: {
                                    show: false
                                }
                            }],
                            series: [{
                                    type: 'bar',
                                    barWidth: '50%',
                                    data: [
                                        {
                                            value: this.platformData[0],
                                            itemStyle: { normal: { color: '#3DDC84' } },
                                        },
                                        {
                                            value: this.platformData[1],
                                            itemStyle: { normal: { color: '#c0c0c0' } },
                                        }
                                    ],
                                    animationDuration
                                }
                            ]
                        })

                    }

                    var chart_platformVersiondv = this.$refs.chart_platform_version;
                    if (chart_platformVersiondv) {

                        this.platformVersionChart = echarts.init(chart_platformVersiondv, 'macarons')

                        this.platformVersionChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            title: { text: 'System Version' },
                            // grid: {
                            //     top: 100,
                            //     left: '2%',
                            //     right: '2%',
                            //     bottom: 100,
                            //     containLabel: true
                            // },
                            yAxis: [{
                                type: 'category',
                                data: this.platformVersionName,
                                axisTick: {
                                    alignWithLabel: true
                                }
                            }],
                            xAxis: [{
                                type: 'value',
                                axisTick: {
                                    show: false
                                }
                            }],
                            series: [{
                                    type: 'bar',
                                    barWidth: '50%',
                                    data: this.platformVersionData,
                                    animationDuration
                                }
                            ]
                        })

                    }

                    var chart_linedv = this.$refs.chart_line;
                    if (chart_linedv) {

                        this.lineChart = echarts.init(chart_linedv, 'macarons')
                        this.lineChart.setOption({
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b} : {c} ({d}%)'
                            },
                            legend: {
                                // left: 'center',
                                // bottom: '10',
                                orient: 'vertical',
                                left: 'right',
                                data: this.appDetectionChartName
                            },
                            color: ['#d9534f','#88d8b0','#5bc0de','#5cb85c','#428bca','#ffcc5c'],
                            title: { text: 'Threat Detection' },
                            series: [{
                                name: 'Threat Detection',
                                type: 'pie',
                                radius: '55%',
                                center: ['50%', '65%'],
                                // roseType: 'radius',
                                // radius: [15, 95],
                                // center: ['50%', '38%'],
                                data: this.appDetectionChartData,
                                animationEasing: 'cubicInOut',
                                animationDuration: 2600
                            }]
                        })

                        // this.lineChart = echarts.init(chart_linedv, 'macarons')
                        //
                        // this.lineChart.setOption({
                        //     xAxis: {
                        //         data: this.eventRecentChartDate,
                        //         boundaryGap: false,
                        //         axisTick: {
                        //             show: false
                        //         }
                        //     },
                        //     grid: {
                        //         left: 10,
                        //         right: 30,
                        //         bottom: 10,
                        //         top: 36,
                        //         containLabel: true
                        //     },
                        //     tooltip: {
                        //         trigger: 'axis',
                        //         axisPointer: {
                        //             type: 'cross'
                        //         },
                        //         padding: [5, 10]
                        //     },
                        //     title: { text: 'Daily Active User' },
                        //     yAxis: {
                        //         axisTick: {
                        //             show: false
                        //         }
                        //     },
                        //     // legend: {
                        //     //     data: ['expected', 'actual'],
                        //     //     left: 'center',
                        //     //     bottom: '10',
                        //     // },
                        //     series: [{
                        //         name: 'Daily Active User',
                        //         smooth: true,
                        //         type: 'line',
                        //         itemStyle: {
                        //             normal: {
                        //                 color: '#3888fa',
                        //                 lineStyle: {
                        //                     color: '#3888fa',
                        //                     width: 2
                        //                 },
                        //                 areaStyle: {
                        //                     color: '#f3f8ff'
                        //                 }
                        //             }
                        //         },
                        //         data: this.eventDAUChartData,
                        //         animationDuration: 2800,
                        //         animationEasing: 'quadraticOut'
                        //     }]
                        // })
                    }

                },
                setPieOptions(showdata) {


                    // this.tmpdata = [
                    //       { value: 320, name: 'SOFTWARE' },
                    //       { value: 240, name: 'HARDWARE' },
                    //       { value: 149, name: 'C​LIENT' },
                    //       { value: 100, name: 'APP_THREAT_DETECTED' },
                    //       { value: 100, name: 'APP_THREAT_RESOLVED' },
                    //       { value: 100, name: 'OS_THREAT_DETECTED' },
                    //       { value: 100, name: 'OS_THREAT_RESOLVED' },
                    //       { value: 100, name: 'NETWORK_THREAT_DETECTED' },
                    //       { value: 59, name: 'NETWORK_THREAT_RESOLVED' }
                    //     ];

                    this.pieChart.setOption({
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/> {b} : {c} ({d}%)'
                        },
                        legend: {
                            // left: 'center',
                            // bottom: '10',
                            orient: 'vertical',
                            left: 'right',
                            data: this.eventTypeChartName
                        },
                        series: [{
                            name: '',
                            type: 'pie',
                            radius: '55%',
                            center: ['50%', '65%'],
                            // roseType: 'radius',
                            // radius: [15, 95],
                            // center: ['50%', '38%'],
                            data: this.eventTypeChartData,
                            animationEasing: 'cubicInOut',
                            animationDuration: 2600
                        }]
                    })

                    this.barChart.setOption({
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        title: { text: 'Daily Event' },
                        grid: {
                            top: 36,
                            left: '2%',
                            right: '2%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [{
                            type: 'category',
                            data: this.eventRecentChartDate,
                            axisTick: {
                                alignWithLabel: true
                            }
                        }],
                        yAxis: [{
                            type: 'value',
                            axisTick: {
                                show: false
                            }
                        }],
                        series: [{
                                name: 'Daily Event',
                                type: 'bar',
                                stack: 'vistors',
                                barWidth: '60%',
                                data: this.eventDayCountChartData,
                                animationDuration
                            }
                            // , {
                            //   name: 'pageB',
                            //   type: 'bar',
                            //   stack: 'vistors',
                            //   barWidth: '60%',
                            //   data: [80, 52, 200, 334, 390, 330, 220],
                            //   animationDuration
                            // }, {
                            //   name: 'pageC',
                            //   type: 'bar',
                            //   stack: 'vistors',
                            //   barWidth: '60%',
                            //   data: [30, 52, 200, 334, 390, 330, 220],
                            //   animationDuration
                            // }
                        ]
                    })

                    this.lineChart.setOption({
                            tooltip: {
                                trigger: 'item',
                                formatter: '{a} <br/>{b} : {c} ({d}%)'
                            },
                            legend: {
                                // left: 'center',
                                // bottom: '10',
                                orient: 'vertical',
                                left: 'right',
                                data: this.appDetectionChartName
                            },
                            title: { text: 'Threat Detection' },
                            color: ['#d9534f','#88d8b0','#5bc0de','#5cb85c','#428bca','#ffcc5c'],
                            series: [{
                                name: 'Threat Detection',
                                type: 'pie',
                                radius: '55%',
                                center: ['50%', '65%'],
                                // roseType: 'radius',
                                // radius: [15, 95],
                                // center: ['50%', '38%'],
                                data: this.appDetectionChartData,
                                animationEasing: 'cubicInOut',
                                animationDuration: 2600
                            }]
                        })


                     this.platformDailyChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {
                                orient: 'vertical',
                                left: 'right'
                            },
                            title: { text: 'Daily Active Device' },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: [{
                                type: 'category',
                                data: this.platformDailyDate,
                                axisTick: {
                                    alignWithLabel: true
                                }
                            }],
                            yAxis: [{
                                type: 'value',
                                axisTick: {
                                    show: false
                                }
                            }],
                            series: [{
                                    name: 'Android Active Device',
                                    type: 'bar',
                                    barWidth: '60%',
                                    data: this.platformDailyAndroidData,
                                    animationDuration
                                },
                                {
                                    name: 'iOS Active Device',
                                    type: 'bar',
                                    barWidth: '60%',
                                    data: this.platformDailyiOSData,
                                    animationDuration
                                }
                            ]
                        })

                    this.platformVersionChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            title: { text: 'System Version' },
                            // grid: {
                            //     top: 100,
                            //     left: '2%',
                            //     right: '2%',
                            //     bottom: 100,
                            //     containLabel: true
                            // },
                            yAxis: [{
                                type: 'category',
                                data: this.platformVersionName,
                                axisTick: {
                                    alignWithLabel: true
                                }
                            }],
                            xAxis: [{
                                type: 'value',
                                axisTick: {
                                    show: false
                                }
                            }],
                            series: [{
                                    type: 'bar',
                                    barWidth: '50%',
                                    data: this.platformVersionData,
                                    animationDuration
                                }
                            ]
                        })

                    this.platformChart.setOption({
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {
                                    type: 'shadow'
                                }
                            },
                            legend: {
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            title: { text: 'Active Device' },
                            // grid: {
                            //     top: 100,
                            //     left: '2%',
                            //     right: '2%',
                            //     bottom: 100,
                            //     containLabel: true
                            // },
                            yAxis: [{
                                type: 'category',
                                data: ['Android','iOS'],
                                axisTick: {
                                    alignWithLabel: true
                                }
                            }],
                            xAxis: [{
                                type: 'value',
                                axisTick: {
                                    show: false
                                }
                            }],
                            series: [{
                                    type: 'bar',
                                    barWidth: '50%',
                                    data: [
                                        {
                                            value: this.platformData[0],
                                            itemStyle: { normal: { color: '#3DDC84' } },
                                        },
                                        {
                                            value: this.platformData[1],
                                            itemStyle: { normal: { color: '#c0c0c0' } },
                                        }
                                    ],
                                    animationDuration
                                }
                            ]
                        })
                    // this.lineChart.setOption({
                    //     xAxis: {
                    //         data: this.eventRecentChartDate,
                    //         boundaryGap: false,
                    //         axisTick: {
                    //             show: false
                    //         }
                    //     },
                    //     grid: {
                    //         left: 10,
                    //         right: 30,
                    //         bottom: 10,
                    //         top: 36,
                    //         containLabel: true
                    //     },
                    //     tooltip: {
                    //         trigger: 'axis',
                    //         axisPointer: {
                    //             type: 'cross'
                    //         },
                    //         padding: [5, 10]
                    //     },
                    //     title: { text: 'Daily Active User' },
                    //     yAxis: {
                    //         axisTick: {
                    //             show: false
                    //         }
                    //     },
                    //     // legend: {
                    //     //     data: ['expected', 'actual'],
                    //     //     left: 'center',
                    //     //     bottom: '10',
                    //     // },
                    //     series: [{
                    //         name: 'Daily Active User',
                    //         smooth: true,
                    //         type: 'line',
                    //         itemStyle: {
                    //             normal: {
                    //                 color: '#3888fa',
                    //                 lineStyle: {
                    //                     color: '#3888fa',
                    //                     width: 2
                    //                 },
                    //                 areaStyle: {
                    //                     color: '#f3f8ff'
                    //                 }
                    //             }
                    //         },
                    //         data: this.eventDAUChartData,
                    //         animationDuration: 2800,
                    //         animationEasing: 'quadraticOut'
                    //     }]
                    // })
                },
                /* eslint-enable */
    /* eslint-disable */
                tableRowClassName({ row }) {

                    // try {
                    //
                    //     if (row.assessments[0].classification != 'N/A') {
                    //
                    //         return 'warning-row';
                    //
                    //     }
                    //
                    // } catch (error) {
                    //
                    //
                    // }

                    return '';
                },
                /* eslint-enable */
    formatIssue(row) {
      try {
        return row.assessments[0].classification;
      } catch (error) {
        return "N/A";
      }
    },

    handleViewJsonCancelClick() {
      this.dialogFormVisible = false;
    },
    handleViewJsonClick(row) {
      // console.log('click....'+JSON.stringify(row))

      this.input = JSON.stringify(row);

      this.dialogFormVisible = true;

      // this.$alert(<pre>{{JSON.stringify(row)}}</pre>, 'View JSON', {
      //   confirmButtonText: 'OK'
      // //   callback: action => {
      // //     this.$message({
      // //       type: 'info',
      // //       message: `action: ${ action }`
      // //     });
      // //   }
      // });
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      this.$store.commit("clearToken");
      this.$router.push("/");
    },
    exportdata() {
      var that = this;
      that.$router.push("/export");
    },
    loadEvents() {
      var that = this;
      this.$axios
        .get("/api/event_feed/", {
          params: {},
          headers: { Authorization: "JWT " + this.$store.state.token }
        })
        .then(res => {
          var result = res.data;

          if (res.status == 200) {
            if (result["msg"] == "success") {
              this.eventList = result["events"];
              this.appDetectionChartName =
                result["app_detection_chart"]["name"];
              this.appDetectionChartData =
                result["app_detection_chart"]["data"];
              this.eventTypeChartData = result["event_type_chart"]["data"];
              this.eventTypeChartName = result["event_type_chart"]["name"];
              this.eventRecentChartDate = result["event_recent_chart"]["date"];
              this.eventDayCountChartData =
                result["event_recent_chart"]["event"];
              this.platformDailyDate = result["platform_chart"]["date"];
              this.platformDailyAndroidData =
                result["platform_chart"]["android"];
              this.platformDailyiOSData = result["platform_chart"]["ios"];
              this.platformData = result["platform_chart"]["platform"];
              this.platformVersionName =
                result["platform_chart"]["version_name"];
              this.platformVersionData =
                result["platform_chart"]["version_data"];
              this.eventDAUChartData = result["event_recent_chart"]["dau"];
              this.seriesData.push([{ value: 123, name: "test" }]);
              this.reload();
            } else {
              alert(result["msg"]);
            }
          } else {
            alert("Login timed out, please log in again");
            that.logout();
          }
        })
        .catch(function() {
          alert("Login timed out, please log in again");
          that.logout();
        });
    }
  }
};
</script>

<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.el-table .warning-row {
  background: oldlace;
}
</style>
