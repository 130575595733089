<template>
    <div id="app">
        <!-- <div v-if="this.$store.state.token">
                <a href="">{{this.$store.state.username}}</a>
            </div>
            <div v-else>
                <router-link to="/login">Login</router-link>
            </div> -->
        <router-view />
    </div>
</template>


<script>
export default {
    name: 'App',
    provide() {
        return {
            reload: this.reload
        }
    },
    data() {
        return {
            isRouterAlive: true
        }
    },
    methods: {
        reload() {
            this.isRouterAlive = false
            this.$nextTick(function() {
                this.isRouterAlive = true
            })
        }
    },
    components: {}
}
</script>

<style lang="stylus">
#app
  font-family Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  margin-top 60px
</style>
