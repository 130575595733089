<template>
  <div>
    <div class="show_data" style="margin-top:-24px;margin-right: 24px">
      <div v-if="this.$store.state.token">
        <p align="right">
          <el-row align="right">
            <el-button @click.native="logout()" icon="el-icon-user-solid">{{
              this.$store.state.username
            }}</el-button>
          </el-row>
        </p>
      </div>
      <div v-else>
        <p align="right">
          <router-link to="/" align="right">Login</router-link>
        </p>
      </div>

      <h2>Phishing Site List Console</h2>

      <div
        ref="dashboard"
        class="info"
        style="margin-top:80px;margin-bottom:20px"
      >
        <el-select v-model="list_type" filterable placeholder="Choose URL Type">
          <el-option
            v-for="item in partnerOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-input
          :rules="rules"
          style="margin-left:20px;width: 240px"
          v-model="list_url"
          type="url"
          placeholder="Web Site URL"
          clearable
          required
        ></el-input>

        <el-button
          type="primary"
          style="margin-left:20px"
          @click="generatedCoupon"
          :loading="this.btnLoading"
          >{{ this.btnName }}</el-button
        >

        <el-button
          type="primary"
          style="margin-left:20px"
          @click="updateDB"
          >Update Databases</el-button
        >

        <div align="center" style="margin-top: 64px">
          <el-card class="box-card" style="margin-top: 64px;width: 70%">
            <div slot="header" class="clearfix">
              <span
                >Phishing Site List (Database last update:
                {{ this.updateDate }})</span
              >
            </div>
            <el-table
              :data="customeList"
              default-text="N/A"
              style="width: 100%"
              border
            >
              <el-table-column prop="list_url" label="URL" min-width="200">
              </el-table-column>
              <el-table-column prop="list_type" label="Type" min-width="100">
              </el-table-column>
              <el-table-column
                prop="create_date"
                label="Create Time"
                min-width="200"
              >
              </el-table-column>

              <el-table-column align="center" label="Action" width="120">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    size="small"
                    icon="el-icon-delete"
                    @click.native="deleteRow(scope.row.list_id)"
                  >
                    Delete
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partnerOptions: [
        {
          value: "blacklist",
          label: "blacklist"
        },
        {
          value: "whitelist",
          label: "whitelist"
        }
      ],
      list_type: "blacklist",
      list_url: "",
      customeList: [],
      btnLoading: false,
      updateDate: "N/A",
      btnName: "Add",
      rules: {
        list_url: [
          {
            required: true,
            message: "Please input a vail URL",
            trigger: "blur"
          },
          {
            validator: function(rule, value, callback) {
              if (
                /^http:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/.test(value) ==
                false
              ) {
                this.$notify({
                  title: "Note",
                  message: "Please input a vail URL",
                  type: "warning"
                });
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      }
    };
  },
  mounted: function() {
    this.loadEvents();
  },
  beforeDestroy() {},
  watch: {},
  methods: {
    /* eslint-disable */
                logout() {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.$store.commit('clearToken');
                    this.$router.push('/PhishingSiteListLogin')
                },
                generatedCoupon() {

                    if (this.list_type == '' || this.list_type == 'n/a'){

                        this.$notify({
                          title: 'Note',
                          message: 'Please select a URL type.',
                          type: 'warning'
                        });

                        return;

                    }

                    if (this.list_url == ''){

                        this.$notify({
                          title: 'Note',
                          message: 'Please input URL.',
                          type: 'warning'
                        });

                        return;

                    }

                    // const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|id|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{4}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;

                    const urlregex = /^(https?:\/\/(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/;

                    if (urlregex.test(this.list_url)) {


                    }else{

                       this.$notify({
                          title: "Note",
                          message: "Please input a vail URL",
                          type: "warning"
                        });

                        return;
                    }

                    var that = this;
                    this.btnLoading = true;
                    this.$axios.get('/api/event_feed/addCustomList/', {
                            params: {
                               list_type:this.list_type,
                                list_url:this.list_url
                            },
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {

                            this.btnLoading = false;
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                  this.list_url = ''

                                  this.customeList = result['data']

                                  this.updateDate = result['date']



                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            this.btnLoading = false;
                            alert('Unknown Error, please try again : '+arg)
                            that.logout()

                        })

                },
            updateDB() {

              this.$axios.get('/api/event_feed/updateCustomList/', {
                            params: {},
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                     this.$notify({
                          title: 'Note',
                          message: 'The server has started to update the database, usually the update will be completed after 5 minutes',
                          type: 'warning'
                        });

                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            alert('Unknown Error, please try again : '+arg)
                            that.logout()

                        })

    },
        deleteRow(row) {
      this.$confirm('Do you confrim delete the item?', 'Messages', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$axios.get('/api/event_feed/delCustomList/', {
                            params: {list_id:row},
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                    this.customeList = result['data']
                                    this.updateDate = result['date']

                                    // this.reload()

                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            alert('Unknown Error, please try again : '+arg)
                            that.logout()

                        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Unknown Error'
        })
      })
    },
                loadEvents() {
                    var that = this;
                    this.$axios.get('/api/event_feed/getCustomList/', {
                            params: {},
                            headers: { 'Authorization': 'JWT ' + this.$store.state.token }
                        })
                        .then((res) => {
                            var result = res.data;


                            if (res.status == 200) {

                                if (result['msg']=='success'){

                                    this.customeList = result['data']
                                    this.updateDate = result['date']
                                    

                                    // this.reload()

                                }else {

                                    alert(result['msg'])
                                }


                            } else {
                                // alert(result['msg'])
                                alert('Login timed out, please log in again')
                                that.logout()
                            }
                        }).catch(function(arg) {
                            alert('Unknown Error, please try again : '+arg)
                            that.logout()

                        })
                }
            }

        }
        </script>

        <style scoped>
        h1,
        h2 {
            font-weight: normal;
        }

        ul {
            list-style-type: none;
            padding: 0;
        }

        li {
            display: inline-block;
            margin: 0 10px;
        }

        a {
            color: #42b983;
        }

        .el-table .warning-row {
          background: #42b983;
        }

        .el-table .success-row {
          background: #f0f9eb;
        }
        .text {
          font-size: 14px;
        }

        .item {
          margin-bottom: 18px;
        }

        .clearfix:before,
        .clearfix:after {
          display: table;
          content: "";
        }
        .clearfix:after {
          clear: both
        }

        .box-card {
          width: 720px;
        }
        </style>
