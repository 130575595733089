<template>
  <div class="phishingSiteList">
    <ExportData msg="PhishingSiteList" />
  </div>
</template>

<script>
// @ is an alias to /src
import ExportData from "@/components/phishingSiteList.vue";

export default {
  name: "phishingSiteList",
  components: {
    ExportData
  }
};
</script>
