import { render, staticRenderFns } from "./PhishingSiteListConsole.vue?vue&type=template&id=59e8a94f&scoped=true"
import script from "./PhishingSiteListConsole.vue?vue&type=script&lang=js"
export * from "./PhishingSiteListConsole.vue?vue&type=script&lang=js"
import style0 from "./PhishingSiteListConsole.vue?vue&type=style&index=0&id=59e8a94f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59e8a94f",
  null
  
)

export default component.exports