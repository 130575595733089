<template>
  <div class="container">
    <h2>iOS Deeplinks</h2>
    <div class="button-group">
      <button
        v-for="page in iosPages"
        :key="page.id"
        @click="openDeeplink(page.url, 'ios')"
        class="btn ios-btn"
      >
        {{ page.name }}
      </button>
    </div>

    <h2>Android Deeplinks</h2>
    <div class="button-group">
      <button
        v-for="page in androidPages"
        :key="page.id"
        @click="openDeeplink(page.url, 'android')"
        class="btn android-btn"
      >
        {{ page.name }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeeplinkButtons",
  data() {
    return {
      iosPages: [
        { id: 1, name: "Report Page", url: "dataguard://openPage?page=report" },
        { id: 2, name: "What's New Page", url: "dataguard://openPage?page=feed" },
        { id: 3, name: "Settings Page", url: "dataguard://openPage?page=settings" }
      ],
      androidPages: [
        { id: 1, name: "Report Page", url: "dataguard://openPage?page=report" },
        { id: 2, name: "What's New Page", url: "dataguard://openPage?page=feed" },
        { id: 3, name: "Settings Page", url: "dataguard://openPage?page=settings" }
      ],

      appStoreUrl: "https://apps.apple.com/hk/app/data-guard/id1113208934", // 替换为你的 App Store URL
      playStoreUrl:
        "https://play.google.com/store/apps/details?id=com.smartone.dataguard" // 替换为你的 Play Store URL
    };
  },
  methods: {
    detectDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        return "android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
      }
      return "unknown";
    },
    openDeeplink(url, intendedPlatform) {
      const device = this.detectDevice();

      // 检查设备类型是否与意图匹配
      if (
        (device === "ios" && intendedPlatform === "android") ||
        (device === "android" && intendedPlatform === "ios")
      ) {
        alert("This link is not intended for your device type.");
        return;
      }

      // 尝试打开应用
      window.location.href = url;

      // 设置一个定时器来检查应用是否成功打开
      // setTimeout(() => {
      //   if (!document.hidden) {
      //     // 如果应用没有打开，重定向到相应的应用商店
      //     if (device === "ios") {
      //       window.location.href = this.appStoreUrl;
      //     } else if (device === "android") {
      //       window.location.href = this.playStoreUrl;
      //     } else {
      //       alert(
      //         "Unable to open the app. Please install it from your device's app store."
      //       );
      //     }
      //   }
      // }, 2000); // 2秒后检查
    }
  }
};
</script>

<style scoped>
.block {
  margin: 30px;
}
.exportdata {
  width: 100%;
  margin: 0 auto;
}
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.ios-btn {
  background-color: #007aff;
  color: white;
}

.ios-btn:hover {
  background-color: #0056b3;
}

.android-btn {
  background-color: #3ddc84;
  color: black;
}

.android-btn:hover {
  background-color: #2ac073;
}
</style>
