    <template>
        <div>
            <div class="phishingSiteList">


                <h2>Phishing Site List</h2>


              <div class="resultlist" align="center" style="margin:0 auto;margin-top: 30px;margin-bottom: 30px;margin-left: 30px;margin-right: 30px;">
                <el-table :data="dataList" default-text="N/A" style="width: 100%" border stripe :row-class-name="tableRowClassName">
                            <el-table-column type="index" fixed width="50"></el-table-column>

                            <el-table-column prop="item_url" label="Phishing Site" min-width="100">
                              <template slot-scope="scope">
                                <a :href="scope.row.url"
                                  target="_blank"
                                  class="buttonText">{{scope.row.url}}</a>
                              </template>
                            </el-table-column>
<!--                            <el-table-column prop="target" label="Target Web Site" min-width="100">-->
<!--                            </el-table-column>-->

                </el-table>
              </div>

            </div>
        </div>
    </template>

    <script>
    export default {
        data() {
            return {
                dataList: [],
            }
        },
        mounted: function() {
                this.exportdata();
            },
        created(){
            //let that = this;
        },
        methods: {
            /* eslint-disable */
            exportdata() {
                var that = this;


                this.$axios.request({
                                url: '/api/event_feed/phishingList/',
                                method: 'GET',
                                params: {

                                }
                            })
                            .then((response) => {

                                var result = response.data;

                                this.lastInput = this.input

                                if (response.status == 200) {

                                    if (result['msg']=='success'){

                                        this.dataList = result['data']

                                    }else {

                                        alert(result['msg'])
                                    }


                                } else {
                                    alert('Unknown Error, please try again')

                                }

                            }).catch(function(arg) {

                                alert('Please try again , '+arg)


                            })
            }
            /* eslint-enable */
        }
    }
    </script>

    <style scoped>
        .block{
            margin: 30px;
        }
        .exportdata {
            width: 100%;
            margin: 0 auto;
        }
    </style>