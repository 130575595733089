<template>
  <div class="home">
    <HelloWorld msg="Smartone Console" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/SMTLogin.vue";

export default {
  name: "SMTLogin",
  components: {
    HelloWorld
  }
};
</script>
